import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import { Container } from 'starterUiComponents'
import PdfFirebaseUrlParser from '../../utils/pdf-firebase-url-parser'


// "SELECT * FROM phpmenus 
// where (appearsin like \"$calledbyfromurl%\" or appearsin like \"%, $calledbyfromurl,%\" or appearsin like \"%, $calledbyfromurl\") 
// and (usetemplate <> \"mainmenu\" and usetemplate <> \"intermenu\") 
// order by disporder, displaytextlink"

/* OLD PAGE:
    https://atsgmembers.com/memarea/bulletins/index.php?calledby=NUMERICBULLETINMENU&usetemplate=intermenu&urltype=internal
*/
// sort: { fields: [disporder, displaytextlink], order: ASC }
const BULLETINS_QUERY = graphql`
  query {
    allMysqlBulletins(
      sort: { fields: [disporder, displaytextlink] }
    ) {
      nodes {
        appearsin
        calledby
        usetemplate
        displaytextlink
        disporder
        displaytextheader
        id
        returntotext2
        dataUrl
      }
    }
  }
`

const getOTB = (arr) => arr.filter((item) => item.displaytextlink ? item.displaytextlink.match(/^OTB-/i) : null)
const getVideo = (arr) => arr.filter((item) => item.displaytextlink ? item.displaytextlink.match(/^Video/i) : null)
const getNumeric = (arr) => arr.filter((item) => !item.displaytextlink || (!item.displaytextlink.match(/^OTB-/i) && !item.displaytextlink.match(/^Video/i)))

const NumericReferencesPage = () => {
  const data = useStaticQuery(BULLETINS_QUERY).allMysqlBulletins.nodes

  const allData = React.useMemo(() => {
    const otb = getOTB(data)
    const video = getVideo(data)
    const numeric = getNumeric(data)
    return [...numeric, ...video, ...otb]
  }, [data])

  /**
   * Gets all the NON quick references pdfs ordered by `calledby` text
   * @returns array
   */
  const getNumericReferences = () => {
    return allData.filter((item) => {
      return (
        item.usetemplate === 'PDF' &&
        !item.displaytextheader.includes('QUICK REFERENCE')
      )
    })
  }

  const numericReferences = getNumericReferences()

  // const oldPdfUrl = "https://atsgmembers.com/memarea/bulletins/";

  return (
    <Container>
      <h1>Tech Library Numeric Index</h1>
      <div>
        <ul>
          {numericReferences &&
            numericReferences.map((reference) => {
              const rel = PdfFirebaseUrlParser.getUrl(reference.dataUrl)
              const pdfLink = `/files/tech-library/${rel}`
              return (
                <li key={reference.id}>
                  <Link to={pdfLink}>{reference.displaytextlink}</Link>
                </li>
              )
            })}
        </ul>
      </div>
    </Container>
  )
}

export default NumericReferencesPage
